import styled from 'styled-components';

export const StyledApplicationLayout = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.text};
  overflow-x: clip;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  /* Fix for IE11 */
  height: 1px;

  > * {
    flex-shrink: 0;
  }
`;

export const StyledMainContent = styled.div`
  flex-grow: 1;
`;
