import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { openProfileToggle } from '~/app/App.actions';
import { IconButton, AccountCircleIcon } from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import ProfileDrawer from './ProfileDrawer';

export interface AccountProps { }

const Account: FC<AccountProps> = () => {
  const { t } = useTranslation('App');
  const dispatch = useDispatch();
  return (
    <>
      <IconButton data-testid="ProfileDrawerButton" Icon={AccountCircleIcon} label={t('mainHeader.profile')} onClick={() => dispatch(openProfileToggle())}></IconButton>
      <ProfileDrawer />
    </>
  );
};
export default Account;
