import { compose } from 'redux';
import { injectAnalyticsScope } from 'ia-react-core';

import { FC } from 'react';
import ErrorLayout, { ErrorLayoutProps } from './ErrorLayout';

export default compose<FC<ErrorLayoutProps>>(
  injectAnalyticsScope({
    key: ({ name }: ErrorLayoutProps) => name,
    pageValues: ({ name, lob }: ErrorLayoutProps) => ({ page: name, lob }),
  }),
)(ErrorLayout);
