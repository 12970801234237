export function getOpenIdConfiguration() {
  return {
    authority: process.env.REACT_APP_API_GATEWAY_AUTHORITY,
    client_id: process.env.REACT_APP_API_GATEWAY_CLIENTID,
    scope: process.env.REACT_APP_API_GATEWAY_MOCK === 'true' ? 'mock-scope' : 'openid identity profile address phone email user_role wellnessprogram.readonly' +
      ' member-enrolment omni.availability omni.promotions omni.secure-messaging omni.products' +
      ' omni.documents omni.online-transactions omni.profile.password.v2 omni.profile',
    response_type: 'code',
    redirect_uri: process.env.REACT_APP_API_GATEWAY_REDIRECT_URI,
  };
}
