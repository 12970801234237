import React, { useEffect } from 'react';
import { Resource, ResourceInfo } from 'omni-package-react-core';
import { useScopedSelector } from 'ia-react-core';
import getAppPath from '~/utils/getAppPath';
import { Versioning } from '~/interfaces/Versioning';
import { useLocation } from 'react-router-dom';

export const ApplicationVersioningResourceInfo: ResourceInfo = {
  name: '/App.VersioningResource',
  url: `${getAppPath()}version.json`,
  dataDst: '/App.Versioning',
};
export const ApplicationVersioningResourceInfoSecondCall: ResourceInfo = {
  name: '/App.VersioningResourceSecondCall',
  url: `${getAppPath()}version.json`,
  dataDst: '/App.VersioningSecondCall',
};

const ApplicationVersioningResource = () => {
  const initial: Versioning = useScopedSelector(ApplicationVersioningResourceInfo.dataDst);
  const second: Versioning = useScopedSelector(ApplicationVersioningResourceInfoSecondCall.dataDst);
  const location = useLocation();

  useEffect(() => {
    if (initial && second && initial.version !== second.version) {
      window.location.reload();
    }
  }, [initial, second, location]);

  return (
    <>
      <Resource
        {...ApplicationVersioningResourceInfo}
        autoRequestIfEmpty
      />
      <Resource
        {...ApplicationVersioningResourceInfoSecondCall}
        autoRequestIfEmpty
      />
    </>
  );
};

export default ApplicationVersioningResource;
