export default {
  error: "Désolé, quelque chose s'est mal passé",
  quit: 'Quitter',
  mainHeader: {
    ClientSpace: 'Espace client',
    profile: 'Profil',
    notifications: 'Notifications',
    logOff: 'Déconnexion',
  },
  menu: {
    home: 'Accueil',
    group_insurance: 'Assurance collective',
    life_insurance: 'Assurance vie',
    indiv_saving: 'Épargne et retraite individuelles',
    group_savings: 'Épargne et retraite collectives',
    doc: 'Mes documents',
    profile: 'Mon profil',
    contact_us: 'Nous joindre',
    back: 'Retour dans l\'ancienne application',
  },
  date: {
    today: "Aujourd'hui",
    yesterday: 'Hier',
    day: {
      0: 'Dimanche',
      1: 'Lundi',
      2: 'Mardi',
      3: 'Mercredi',
      4: 'Jeudi',
      5: 'Vendredi',
      6: 'Samedi',
    },
    month: {
      0: 'Janvier',
      1: 'Février',
      2: 'Mars',
      3: 'Avril',
      4: 'Mai',
      5: 'Juin',
      6: 'Juillet',
      7: 'Août',
      8: 'Septembre',
      9: 'Octobre',
      10: 'Novembre',
      11: 'Décembre',
    },
  },
};
