import { useActivity, requestResourceByName } from 'ia-react-core';
import { FC, useContext, useMemo } from 'react';
import { ApplicationAvailabilityContext, AuthenticationContext, Availability } from 'omni-package-react-core';
import { getOmniLogger } from 'omni-package-react-logging';
import { useDispatch } from 'react-redux';
import getTokenManager from '~/utils/getTokenManager';
import { getAvailabilityConfiguration } from '~/utils/getAvailabilityConfiguration';
import { refreshCurrentSession } from './refreshCurrentSession';
import { validateAvailability } from './validateAvailability';
import { refreshToken } from './refreshToken';
import { ApplicationVersioningResourceInfoSecondCall } from '../ApplicationVersioningResource/ApplicationVersioningResource';

export interface ActivityManagerProps { }

const oneMinute = 60000;
const fiveMinutes = 300000;

const ActivityManager: FC<ActivityManagerProps> = () => {
  const { authenticationInfo } = useContext(AuthenticationContext);
  const availabilityCtx = useContext(ApplicationAvailabilityContext);
  const logger = getOmniLogger('we-client-space-activity-manager');
  const tokenManager = useMemo(() => getTokenManager(), []);
  const dispatch = useDispatch();
  const config = useMemo(() => getAvailabilityConfiguration(), []);

  useActivity(() => {
    dispatch(requestResourceByName(ApplicationVersioningResourceInfoSecondCall.name));
    refreshToken(authenticationInfo.isAuthenticationProcessed, logger, tokenManager);
    validateAvailability(
      authenticationInfo.isAuthenticationProcessed,
      config.group,
      config.company,
      config.experience,
      (av: Availability) => availabilityCtx.saveAvailability(av),
    );
  }, [authenticationInfo.isAuthenticationProcessed], { frequency: oneMinute, strategy: 'leading' });

  useActivity(() => {
    refreshCurrentSession(authenticationInfo.isAuthenticationProcessed, logger);
  }, [authenticationInfo.isAuthenticationProcessed], { frequency: fiveMinutes, strategy: 'leading' });

  return null;
};

export default ActivityManager;
