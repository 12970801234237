import { NavigationGuard, NavigationGuardProps, closeModalById, useScopedSelector } from 'ia-react-core';
import { MfaOnboarding, MfaOnboardingResourceInfo, Constants as AccountConstants } from 'we-package-react-account';
import { PageContext } from 'omni-package-react-core';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Location, useLocation } from 'react-router-dom';
import Constants from '~/app/Constants';

export interface CanQuitGuardProps extends NavigationGuardProps {}

export const CanQuitGuardName = 'CanQuitGuard';

const CanQuitGuard: FC<CanQuitGuardProps> = (props) => {
  const { t } = useTranslation('CanQuitGuard');
  const { path } = useContext(PageContext);
  const location = useLocation();

  const isMfaPage = useMemo(() => (
    location.pathname.startsWith(AccountConstants.MfaValidationPath)
  ), [location.pathname]);

  const mfaOnboarding: MfaOnboarding = useScopedSelector(MfaOnboardingResourceInfo.dataDst);
  const onboardingStatus = (mfaOnboarding?.data as { onboardingStatus: string })?.onboardingStatus;
  const dispatch = useDispatch();
  const isUpdatingMfa = isMfaPage && onboardingStatus === 'completed';

  const handleNavigate = useCallback((options: { data: { nextLocation: Location }; modalId: number }) => {
    if (options.data.nextLocation.pathname !== Constants.PagesUrl.LogOff && !isUpdatingMfa) {
      dispatch(closeModalById(options.modalId));
      return [Constants.PagesUrl.LogOff, { data: { finalPath: options.data.nextLocation.pathname } }] as [string, Object];
    }

    return true;
  }, [dispatch, isUpdatingMfa]);

  return (
    <NavigationGuard
      path={path}
      modalMessage={isUpdatingMfa ? undefined : t('modalMessage')}
      reverseButtons={!isUpdatingMfa}
      onNavigate={handleNavigate}
      {...props}
    />
  );
};
export default CanQuitGuard;
