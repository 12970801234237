import React, { FC, ReactNode, useContext, useEffect, useCallback, useMemo } from 'react';
import { PersonalInformationsState } from 'we-package-react-personal-informations';
import { AccountResource, LastLogOnSetup, MfaOnboardingResource } from 'we-package-react-account';
import { ApplicationAvailability, UserAccountContext, ClaimsProvider, AuthenticationContext, useFeatureState } from 'omni-package-react-core';
import getTokenManager from '~/utils/getTokenManager';
import ApplicationVersioningResource from '~/components/ApplicationVersioningResource';
import { AnalyticsGuidResource } from 'omni-package-react-analytics';
import { ClientWelcomeModal } from 'we-package-react-notifications';
import Constants from '~/app/Constants';
import { getAvailabilityConfiguration } from '~/utils/getAvailabilityConfiguration';
import { matchRoutes, useLocation } from 'react-router-dom';
import { useScopedSelector } from 'ia-react-core';
import TermsOfUseModal from '../../components/TermsOfUseModal';
import AppLoadingIcon from '../../components/AppLoadingIcon';
import AccountSecurityValidator from './AccountSecurityValidator/AccountSecurityValidator';

export interface ContextWrapperProps {
  children: ReactNode;
}

const ContextWrapper: FC<ContextWrapperProps> = ({ children }) => {
  const userAccountCtx = useContext(UserAccountContext);
  const isProspect: boolean = useFeatureState(PersonalInformationsState, (state) => state?.info?.identification?.isProspect);
  const isClient: boolean = useScopedSelector('/App.features.personalInformation.info.identification.behaviours.isClient');

  const authCtx = useContext(AuthenticationContext);
  const tokenManager = useMemo(() => getTokenManager(), []);
  useEffect(() => {
    userAccountCtx.saveIsProspect(isProspect);
    userAccountCtx.saveIsClient(isClient);
  }, [isProspect]);// eslint-disable-line react-hooks/exhaustive-deps

  const location = useLocation();

  const includeAvailabilityCheck = useMemo(() => (
    matchRoutes(Constants.AvailabilityCheckRoutes.map((path) => ({ path })), location) !== null
  ), [location]);

  const getContainer = useCallback(() => (includeAvailabilityCheck ? ApplicationAvailability : React.Fragment), [includeAvailabilityCheck]);
  const config = useMemo(() => getAvailabilityConfiguration(), []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const p: any = useMemo(() => includeAvailabilityCheck ? { ...config, loading: <AppLoadingIcon />, unavailablePageUrl: Constants.PagesUrl.UnavailablePage } : {}, [config, includeAvailabilityCheck]);
  const Container = getContainer();

  /* istanbul ignore next */
  const tempHasClaim = (claim: string, value?: string): Promise<boolean> => tokenManager.hasClaim(claim, value);

  return (
    <Container {...p}>
      {authCtx?.authenticationInfo?.isAuthenticated === true ?
        <>
          <ApplicationVersioningResource />
          <AccountResource autoRequestIfEmpty />
          <MfaOnboardingResource autoRequest />
          <AnalyticsGuidResource autoRequest />
          <LastLogOnSetup />
          <ClaimsProvider
            termsOfUseClaim="ia_client_space_terms_of_use_accepted"
            dcaClaim="on-behalf-of"
            hasClaim={tempHasClaim}
          >
            <TermsOfUseModal>
              <ClientWelcomeModal />
              <AccountSecurityValidator>
                {children}
              </AccountSecurityValidator>
            </TermsOfUseModal>
          </ClaimsProvider>
        </> : <>{children}</>}
    </Container>
  );
};

export default ContextWrapper;
