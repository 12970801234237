import React, { ReactNode } from 'react';
import {
  CustomPageLoader, FormLayoutProps, ProtectedContainerProps,
  useWaitUntilResource,
  TableOfContentsResourceInfo,
  getPage,
} from 'omni-package-react-core';
import { ErrorLayoutProps } from '~/layouts/ErrorLayout/ErrorLayout';
import { ApplicationLayoutProps } from '~/layouts/ApplicationLayout/ApplicationLayout';
import Constants from './Constants';

const HomePage = () => <CustomPageLoader loader={() => import('../pages/HomePage')} />;
const ProfilePage = () => <CustomPageLoader loader={() => import('../pages/ProfilePage')} />;
const ContactUsPage = () => <CustomPageLoader loader={() => import('../pages/Sitecore/ContactUsPage')} />;
const FaqPage = () => <CustomPageLoader loader={() => import('../pages/Sitecore/FaqPage')} />;
const TermsOfUsePage = () => (
  <CustomPageLoader
    loader={() => import('../pages/Sitecore/TermsOfUsePage')}
    delayPageViewUntil={useWaitUntilResource({ name: `${TableOfContentsResourceInfo.name}-terms` })}
  />
);
const ProtectionInfoPage = () => (
  <CustomPageLoader
    loader={() => import('../pages/Sitecore/ProtectionInfoPage')}
    delayPageViewUntil={useWaitUntilResource({ name: `${TableOfContentsResourceInfo.name}-pers-info` })}
  />
);
const SecurityInfoFormPage = () => <CustomPageLoader loader={() => import('../pages/SecurityInformation/SecurityInfoFormPage')} />;
const SecurityInfoConfirmationPage = () => <CustomPageLoader loader={() => import('../pages/SecurityInformation/SecurityInfoConfirmationPage')} />;
const TechnicalErrorPage = () => <CustomPageLoader loader={() => import('../pages/Errors/TechnicalErrorPage')} />;
const UnauthorizedPage = () => <CustomPageLoader loader={() => import('../pages/Errors/UnauthorizedPage')} />;
const UnavailablePage = () => <CustomPageLoader loader={() => import('../pages/Errors/UnavailablePage')} />;
const TestPage = () => <CustomPageLoader loader={() => import('../pages/TestPage')} />;
const NotFoundPage = () => <CustomPageLoader loader={() => import('../pages/Errors/NotFoundPage')} />;

const getInternalRoutes = (
  ProtectedContainer: React.FC<ProtectedContainerProps>,
  ApplicationLayout: React.FC<ApplicationLayoutProps>,
  ErrorLayout: React.FC<ErrorLayoutProps>,
  isAuthenticated: boolean,
  FormLayout: React.FC<FormLayoutProps>,
) => {
  const t: ReactNode[] = [];
  [
    {
      exact: true,
      path: '',
      component: (<ApplicationLayout name="Home" lob="corpo"><HomePage /></ApplicationLayout>),
      protected: true,
      index: true,
    },
    {
      exact: true,
      path: Constants.PagesUrl.Profile,
      component: (<ApplicationLayout name="Profile" lob="corpo"><ProfilePage /></ApplicationLayout>),
      protected: true,
    },
    {
      exact: true,
      path: Constants.PagesUrl.ContactUs,
      component: (<ApplicationLayout name="ContactUs" lob="corpo"><ContactUsPage /></ApplicationLayout>),
      protected: false,
    },
    {
      exact: true,
      path: Constants.PagesUrl.Faq,
      component: (<ApplicationLayout name="faq" lob="corpo"><FaqPage /></ApplicationLayout>),
      protected: false,
    },
    {
      exact: true,
      path: Constants.PagesUrl.GeneralTermsOfUse,
      component: (<ApplicationLayout name="general-terms-of-use" lob="corpo"><TermsOfUsePage /></ApplicationLayout>),
      protected: false,
    },
    {
      exact: true,
      path: Constants.PagesUrl.PersonalInformationsProtection,
      component: (<ApplicationLayout name="personal-informations-protection" lob="corpo"><ProtectionInfoPage /></ApplicationLayout>),
      protected: false,
    },
    {
      exact: true,
      path: Constants.PagesUrl.SecurityInfoFormPage,
      component: (<FormLayout canQuit={false} needHelp returnPage={Constants.PagesUrl.HomePage} name="update-security-information" lob="corpo"><SecurityInfoFormPage /></FormLayout>),
      protected: true,
    },
    {
      exact: true,
      path: Constants.PagesUrl.SecurityInfoConfirmationPage,
      component: (<FormLayout needHelp returnPage={Constants.PagesUrl.HomePage} name="update-security-confirmation" lob="corpo"><SecurityInfoConfirmationPage /></FormLayout>),
      protected: true,
    },
    {
      exact: true,
      path: Constants.PagesUrl.Error,
      component: (<ApplicationLayout name="Error" lob="corpo"><TechnicalErrorPage /></ApplicationLayout>),
      protected: true,
    },
    {
      exact: true,
      path: Constants.PagesUrl.TechnicalErrorPage,
      component: (<ErrorLayout name="Error" lob="corpo"><TechnicalErrorPage /></ErrorLayout>),
      protected: false,
    },
    {
      exact: true,
      path: Constants.PagesUrl.Unauthorized,
      component: (<ApplicationLayout name="Unauthorized" lob="corpo"><UnauthorizedPage /></ApplicationLayout>),
      protected: true,
    },
    {
      exact: true,
      path: Constants.PagesUrl.TestPage,
      component: (<ApplicationLayout name="TestPage" lob="corpo"><TestPage /></ApplicationLayout>),
      protected: true,
    },
    {
      exact: true,
      path: Constants.PagesUrl.UnavailablePage,
      component: (<ErrorLayout name="Unavailable" lob="corpo"><UnavailablePage /></ErrorLayout>),
      protected: isAuthenticated,
    },
    {
      exact: true,
      path: '*',
      component: (<ApplicationLayout lob="corpo" name="NotFound"><NotFoundPage /></ApplicationLayout>),
      protected: false,
    },
  ].forEach((p) => t.push(getPage(p, isAuthenticated, ProtectedContainer)));

  return t;
};

export default getInternalRoutes;
