import { FC } from 'react';
import { compose } from 'redux';
import { injectTranslations } from 'ia-react-core';
import MobileBanner, { MobileBannerProps } from './MobileBanner';
import fr from './translations/fr';
import en from './translations/en';

export default compose<FC<MobileBannerProps>>(
  injectTranslations('MobileBanner', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(MobileBanner);
