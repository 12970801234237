import { validateAvailability as validateAvailabilityCore, Availability } from 'omni-package-react-core';

export const validateAvailability = (
  isAuthenticated: boolean,
  group: string,
  company: '0' | '6',
  experience: 'CLIENT' | 'ADVISOR' | 'EMPLOYEE',
  saveAvailability: (av: Availability) => void,
) => {
  if (isAuthenticated) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    validateAvailabilityCore(group, company, experience)
      .then((availability: Availability) => saveAvailability(availability));
  }
};
