import { changeLanguage, SideNavAnchor } from 'ia-react-core';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export interface ChangeLanguageButtonProps { }

const ChangeLanguageButton: FC<ChangeLanguageButtonProps> = () => {
  const language = useTranslation().i18n?.language?.substring(0, 2) || 'en';
  const isCurrentLanguageFrench = useMemo(() => language === 'fr', [language]);
  const nextLanguage = useMemo(() => isCurrentLanguageFrench ? 'en' : 'fr', [isCurrentLanguageFrench]);
  const nextLanguageText = useMemo(() => isCurrentLanguageFrench ? 'English' : 'Français', [isCurrentLanguageFrench]);
  const dispatch = useDispatch();

  return (
    <SideNavAnchor onClick={() => dispatch(changeLanguage(nextLanguage))} data-testid="LanguageButton">{nextLanguageText}</SideNavAnchor>
  );
};

export default ChangeLanguageButton;
