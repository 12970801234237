import styled from 'styled-components';
import { SideNav, SideNavProps } from 'ia-react-core';
import { FC } from 'react';

export const StyledDrawerSideNav = styled<FC<SideNavProps>>(SideNav)`
border-top: 1px ${({ theme }) => theme.colors.gray3} solid;
`;

export const StyledDiv = styled('div')`
margin: 0px 30px 36px 16px;
`;
