import React, { FC } from 'react';
import { Drawer as DrawerCore, SideNavLink, SideNavAnchor, useScopedSelector } from 'ia-react-core';
import { useDispatch } from 'react-redux';
import { openProfileToggle } from '~/app/App.actions';
import { useTranslation } from 'react-i18next';
import { redirectTo, useFeatureState } from 'omni-package-react-core';
import { useNavigate } from 'react-router-dom';
import { AppState } from '~/interfaces/AppState';
import { PersonalInformationsState, PersonalInformation } from 'we-package-react-personal-informations';
import Constants from '~/app/Constants';
import { StyledDrawerSideNav } from './ProfileDrawer.styles';
import ChangeLanguageButton from './ChangeLanguageButton';
import getPersonalInformation from './getPersonalInformation';

export interface ProfileDrawerProps { }

const ProfileDrawer: FC<ProfileDrawerProps> = () => {
  const isOpen: boolean = useScopedSelector((state: AppState) => state.isProfileDrawerOpen);
  const personalInformation: PersonalInformation = useFeatureState(PersonalInformationsState, (state) => state?.info);
  const personalInfos = getPersonalInformation(personalInformation);

  const dispatch = useDispatch();
  const dispatchOpenProfileToggle = () => {
    dispatch(openProfileToggle());
  };
  const navigate = useNavigate();
  const { t } = useTranslation('App');

  return (
    <DrawerCore
      name="Profile"
      noContentPadding
      drawerTitle={personalInfos.fullName}
      drawerSubTitle={personalInfos.email}
      isOpened={isOpen}
      position="right"
      onClose={() => dispatchOpenProfileToggle()}
    >
      <DrawerCore.Content>
        <StyledDrawerSideNav>
          <SideNavLink data-testid="MyProfile" to="/profile" onClick={() => { dispatchOpenProfileToggle(); }}>{t('menu.profile')}</SideNavLink>
          <ChangeLanguageButton />
          <SideNavAnchor
            onClick={() => {
              dispatchOpenProfileToggle(); redirectTo(Constants.PagesUrl.LogOff, navigate);
            }}
            data-testid="LogOffButton"
          >{t('mainHeader.logOff')}
          </SideNavAnchor>
        </StyledDrawerSideNav>
      </DrawerCore.Content>
    </DrawerCore>
  );
};

export default ProfileDrawer;
