import { compose } from 'redux';
import { injectAnalyticsScope } from 'ia-react-core';

import { FC } from 'react';
import FormLayout, { FormLayoutProps } from './FormLayout';

export default compose<FC<FormLayoutProps>>(
  injectAnalyticsScope({
    key: ({ name }: FormLayoutProps) => name,
    pageValues: ({ name, lob }: FormLayoutProps) => ({ page: name, lob }),
  }),
)(FormLayout);
