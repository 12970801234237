import { P, TertiaryButton, media } from 'ia-react-core';
import styled from 'styled-components';

export const StyledContentDiv = styled.div`
    background-color:${({ theme }) => theme.colors.blue14};
    padding: 10px 16px;
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    min-height: 40px; 
    height: 40px; 
    ${media.md`
      padding: 10px 32px;
  `}
`;

export const StyledCloseDiv = styled.div`
  margin: auto;
`;

export const StyledIaIconDiv = styled.div`
  background: white;
  height: 23px;
  width: 28px; 
  padding: 8px 6px 9px 6px;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 4px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.15);

  svg {
    width: 30px;
    height: 30px;
  }

  ${media.md`
    margin-left: 16px;
  `}
`;

export const StyledDiv = styled.div`
  display: flex;
`;

export const StyledSpan = styled(P)`
  font-size: 14px;
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  ${media.md`
    margin-left: 12px;
    font-size: 17px;
  `}
`;

export const StyledTertiaryButton = styled(TertiaryButton)`
 margin-left: 10px;
 padding: 0px 12px;
 font-size: 14px;
`;

export const StyledIaSpan = styled.span`
  font-weight: bold;
`;
