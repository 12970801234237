import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { Account, AccountResourceInfo, Constants as AccountConstants, MfaOnboardingResourceInfo, MfaOnboarding } from 'we-package-react-account';
import { useScopedSelector } from 'ia-react-core';
import Constants from '~/app/Constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { redirectTo, useResourceState } from 'omni-package-react-core';
import AppLoadingIcon from '~/components/AppLoadingIcon/AppLoadingIcon';

export interface AccountSecurityValidatorProps {
  children: ReactNode;
}

const AccountSecurityValidator: FC<AccountSecurityValidatorProps> = ({ children }) => {
  const account: Account = useScopedSelector(AccountResourceInfo.dataDst);
  const mfaOnboarding: MfaOnboarding = useScopedSelector(MfaOnboardingResourceInfo.dataDst);
  const onboardingStatus = (mfaOnboarding?.data as { onboardingStatus: string })?.onboardingStatus;
  const accountState = useResourceState(AccountResourceInfo.name);
  const onboardingState = useResourceState(MfaOnboardingResourceInfo.name);

  const isAccountLoading = Boolean(!account && !accountState?.error && (accountState?.pending !== false || accountState.requestCount === 0));
  const isOnboardingLoading = Boolean(!onboardingStatus && !onboardingState?.error && (onboardingState?.pending !== false || onboardingState?.requestCount === 0));

  const navigate = useNavigate();
  const location = useLocation();
  const showConfirmPage = (location.state as {
    data?: {
      newUsername?: string;
      showConfirmPage?: boolean;
    };
  })?.data?.showConfirmPage;

  const securedRoutes = useMemo(() => ([
    { path: AccountConstants.InvalidUsernameFormPath, required: (account?.behaviours?.isUsernameValidationRequired || showConfirmPage) || localStorage.getItem('we-account-isUsernameValidationRequired') === 'true' },
    { path: Constants.PagesUrl.SecurityInfoFormPage, required: (account?.behaviours?.isSecurityInformationValidationRequired || showConfirmPage) || localStorage.getItem('we-account-isSecurityInformationValidationRequired') === 'true' },
    {
      path: AccountConstants.MfaValidationPath,
      required: onboardingStatus === 'active' || localStorage.getItem('we-mfa-onboarding') === 'true',
      allowed: onboardingStatus === 'completed' || isOnboardingLoading,
    },
  ]), [account?.behaviours, onboardingStatus, showConfirmPage, isOnboardingLoading]);

  const currentSecuredRoutes = useMemo(() => (
    securedRoutes.find(({ path }) => location.pathname.startsWith(path))
  ), [location.pathname, securedRoutes]);

  const firstRequiredRoute = useMemo(() => (
    securedRoutes.find(({ required }) => required)
  ), [securedRoutes]);

  const needsToGoHome = Boolean(currentSecuredRoutes && !currentSecuredRoutes.required && !currentSecuredRoutes.allowed);
  const needsToGoToSecuredRoute = Boolean(!currentSecuredRoutes && firstRequiredRoute);

  useEffect(() => {
    if (needsToGoHome) {
      redirectTo(Constants.PagesUrl.HomePage, navigate, {}, { replace: true });
    } else if (needsToGoToSecuredRoute) {
      redirectTo(firstRequiredRoute.path, navigate, {}, { replace: true });
    }
  }, [currentSecuredRoutes, firstRequiredRoute, navigate, needsToGoHome, needsToGoToSecuredRoute]);

  if (isAccountLoading || isOnboardingLoading || needsToGoHome || needsToGoToSecuredRoute) {
    return (<AppLoadingIcon />);
  }

  return (<>{children}</>);
};

export default AccountSecurityValidator;
