import { CLOSE_ALERT, OPEN_PROFILE_TOGGLE } from '~/app/App.actions';
import { GlobalAction } from '../interfaces/GlobalAction';
import { AppState } from '../interfaces/AppState';

const appReducer = (state: AppState, action: GlobalAction): AppState => {
  switch (action.type) {
    case CLOSE_ALERT:
      return closeAlert(state);
    case OPEN_PROFILE_TOGGLE:
      return OpenProfileToggle(state);
    default:
      return state;
  }
};

const closeAlert = (state: AppState): AppState => ({
  ...state,
  alert: {
    ...state.alert,
    show: false,
  },
});
const OpenProfileToggle = (state: AppState): AppState => ({
  ...state,
  isProfileDrawerOpen: !state.isProfileDrawerOpen,
});

export default appReducer;
