import { InjectTranslationsProps } from 'ia-react-core';
import { AuthenticationContext } from 'omni-package-react-core';
import React, { FC, ReactNode, useContext, useMemo } from 'react';
import { Routes } from 'react-router-dom';
import { getPages as getPagesAccount } from 'we-package-react-account';
import { getPages as getPagesDocCentre } from 'we-package-react-document-centre';
import { AvailableDocumentsModal, DocumentsTypesResource, getPages as getPagesElectronicDocuments } from 'we-package-react-electronic-documents';
import { getPages as getPagesNews } from 'we-package-react-news';
import { getPages as getPagesPersInfo } from 'we-package-react-personal-informations';
import { getPages as getPagesPreferences } from 'we-package-react-preferences';
import { getPages as getPagesSecMsg } from 'we-package-react-secured-messaging';
import ApplicationLayout from '~/layouts/ApplicationLayout';
import ErrorLayout from '~/layouts/ErrorLayout';
import getTokenManager from '~/utils/getTokenManager';
import FormLayout from '../layouts/FormLayout';
import MainLayout from '../layouts/MainLayout';
import Constants from './Constants';
import getInternalRoutes from './getInternalRoutes';

export interface AppProps { }
export interface AppComposedProps extends AppProps, InjectTranslationsProps { }

const App: FC<AppComposedProps> = () => {
  const authCtx = useContext(AuthenticationContext);
  const tokenManager = useMemo(() => getTokenManager(), []);
  const allPages = useMemo(
    () => ({
      electronicDocuments: getPagesElectronicDocuments(
        React.Fragment,
        authCtx?.authenticationInfo?.isAuthenticated,
        ApplicationLayout,
      ),
      personalInformation: getPagesPersInfo(
        React.Fragment,
        authCtx?.authenticationInfo?.isAuthenticated,
        tokenManager.getToken,
        FormLayout,
        Constants.PagesUrl.Profile,
      ),
      documentCentre: getPagesDocCentre(
        React.Fragment,
        authCtx?.authenticationInfo?.isAuthenticated,
      ),
      news: getPagesNews(
        React.Fragment,
        authCtx?.authenticationInfo?.isAuthenticated,
        ApplicationLayout,
      ),
      account: getPagesAccount(
        React.Fragment,
        authCtx?.authenticationInfo?.isAuthenticated,
        FormLayout,
        Constants.PagesUrl.Profile,
      ),
      securedMessaging: getPagesSecMsg(
        React.Fragment,
        authCtx?.authenticationInfo?.isAuthenticated,
        FormLayout,
        ApplicationLayout,
      ),
      preferences: getPagesPreferences(
        React.Fragment,
        authCtx?.authenticationInfo?.isAuthenticated,
        FormLayout,
        Constants.PagesUrl.Profile,
        [
          <DocumentsTypesResource key="DocumentsTypesResource" autoRequest requestOnLanguageChange />,
          <AvailableDocumentsModal key="AvailableDocumentsModal" />,
        ] as ReactNode[],
      ),
      internal: getInternalRoutes(
        React.Fragment,
        ApplicationLayout,
        ErrorLayout,
        authCtx?.authenticationInfo?.isAuthenticated,
        FormLayout,
      ),
    }),
    [authCtx?.authenticationInfo?.isAuthenticated, tokenManager.getToken],
  );

  return (
    <MainLayout>
      <Routes>
        {allPages.securedMessaging}
        {allPages.documentCentre}
        {allPages.personalInformation}
        {allPages.preferences}
        {allPages.account}
        {allPages.news}
        {allPages.electronicDocuments}
        {allPages.internal}
      </Routes>
    </MainLayout>
  );
};

export default App;
