import React, { FC, useContext, useCallback, useMemo, useRef } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import {
  getWrappedDisplayName,
  configAnalytics,
  get,
  REDUX,
} from 'ia-react-core';
import { ReactReduxContext } from 'react-redux';
import getAnalyticsManager from '~/utils/getAnalyticsManager';

const analyticsManager = getAnalyticsManager();

const configAnalyticsWithDelay = (WrappedComponent: FC) => {
  const ConfigAnalytics: FC = (props) => {
    const loadingPageRef = useRef(null);
    const unsubscribeRef = useRef<() => void>();
    const resolveRef = useRef<() => void>();
    const { store } = useContext(ReactReduxContext);

    const getLoadingPage = useCallback(() => {
      const state = store.getState();
      return get(state, `${REDUX.ROUTE_REDUCER_KEY}.loadingPage`);
    }, [store]);

    const watchForPageLoaded = useCallback(() => {
      const newLoadingPage = getLoadingPage();
      const hasPageCompletedLoading = newLoadingPage === null;
      const wasPageLoading = Boolean(loadingPageRef.current);
      if (hasPageCompletedLoading && wasPageLoading) {
        unsubscribeRef.current?.();
        resolveRef.current?.();
      }
      loadingPageRef.current = newLoadingPage;
    }, [getLoadingPage]);

    const delayPageViewUntil = useCallback(() => (
      new Promise<void>((resolve) => {
        loadingPageRef.current = getLoadingPage();
        resolveRef.current = resolve;
        unsubscribeRef.current = store.subscribe(watchForPageLoaded);
      })
    ), [getLoadingPage, store, watchForPageLoaded]);

    const ConfiguredComponent = useMemo(() => (
      configAnalytics({
        managers: [
          analyticsManager,
        ],
        delayPageViewUntil,
      })(WrappedComponent)
    ), [delayPageViewUntil]);

    return (
      <ConfiguredComponent {...props} />
    );
  };

  ConfigAnalytics.displayName = getWrappedDisplayName('ConfigAnalytics', WrappedComponent);

  return hoistNonReactStatics(ConfigAnalytics, WrappedComponent);
};

export default configAnalyticsWithDelay;
