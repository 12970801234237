import React, { FC, ReactNode, useMemo, useContext, useEffect, useCallback } from 'react';
import { getOmniLogger } from 'omni-package-react-logging';
import { useNavigate, useLocation, Location, matchRoutes } from 'react-router-dom';
import { AuthenticationContext, redirectTo } from 'omni-package-react-core';
import Constants from '~/app/Constants';
import getTokenManager from '~/utils/getTokenManager';
import { closeCurrentSession } from 'aw-package-react-authentication';
import AppLoadingIcon from '../AppLoadingIcon';

export interface OpenIdConnectorWrapperProps {
  children: ReactNode;
}

const OpenIdConnectorWrapper: FC<OpenIdConnectorWrapperProps> = ({ children }) => {
  const authCtx = useContext(AuthenticationContext);
  const location = useLocation();
  const navigate = useNavigate();
  const logger = getOmniLogger('we-client-space-oidc-wrapper');
  const tokenManager = useMemo(() => getTokenManager(), []);
  const authenticationContext = useContext(AuthenticationContext);
  const { state }: Location<{ data?: { finalPath?: string } }> = useLocation();

  const isProtectedRoute = useMemo(() => (
    matchRoutes(Constants.ProtectedRoutes.map((path) => ({ path })), location) !== null
  ), [location]);

  const isPublicRouteWithAuthLayout = useMemo(() => (
    matchRoutes(Constants.PublicRoutesWithAuthenticatedLayout.map((path) => ({ path })), location) !== null
  ), [location]);

  const isAuthorizeCallback = location.pathname === Constants.PagesUrl.AuthorizeCallback;
  const isLogOffPage = location.pathname === Constants.PagesUrl.LogOff;

  const handleLogOff = useCallback(async () => {
    try {
      await tokenManager.logOff();
      await closeCurrentSession({ backgroundLogOff: Boolean(state?.data?.finalPath) });
      authenticationContext.saveAuthenticationInfo({ isAuthenticated: false, isAuthenticationProcessed: false });
      if (state?.data?.finalPath) {
        window.location.replace(state?.data?.finalPath);
      }
    } catch (error: unknown) {
      logger.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.data?.finalPath]);

  useEffect(() => {
    if (isAuthorizeCallback) {
      tokenManager.authenticate()
        .then(() => {
          authenticationContext.saveAuthenticationInfo({ isAuthenticated: true, isAuthenticationProcessed: true });
          redirectTo(sessionStorage.getItem('redirect-url') ?? Constants.PagesUrl.HomePage, navigate);
        })
        .catch((error: unknown) => {
          logger.error(error);
          redirectTo(Constants.PagesUrl.HomePage, navigate);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else if ((isProtectedRoute && !authCtx.authenticationInfo.isAuthenticationProcessed)
      || (isPublicRouteWithAuthLayout && authCtx.authenticationInfo.isAuthenticated && !authCtx.authenticationInfo.isAuthenticationProcessed)) {
      sessionStorage.setItem('redirect-url', location.pathname + location.search);

      tokenManager.init()
        .then((url) => { window.location.href = url; })
        .catch((err: unknown) => {
          logger.error(err);
          redirectTo(Constants.PagesUrl.TechnicalErrorPage, navigate);
        });
    }
  }, [authCtx.authenticationInfo.isAuthenticationProcessed, authCtx.authenticationInfo.isAuthenticated, authenticationContext, isAuthorizeCallback, isProtectedRoute, location.pathname, location.search, logger, navigate, tokenManager, isPublicRouteWithAuthLayout]);

  useEffect(() => {
    if (isLogOffPage) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleLogOff();
    }
  }, [handleLogOff, isLogOffPage]);

  if (isAuthorizeCallback || isLogOffPage) {
    return <AppLoadingIcon />;
  }

  if (!isProtectedRoute || authCtx.authenticationInfo.isAuthenticationProcessed) {
    return (<>{children}</>);
  }

  return null;
};

export default OpenIdConnectorWrapper;
