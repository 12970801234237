import { SideNavLayout } from 'ia-react-core';
import React, { FC, ReactNode, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ApplicationLayoutProps } from 'omni-package-react-core';

export interface ErrorLayoutProps extends ApplicationLayoutProps {
  children: ReactNode;
}

const ErrorLayout: FC<ErrorLayoutProps> = ({ children }) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation('App');

  return (
    <SideNavLayout
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      appName={t('mainHeader.ClientSpace').toUpperCase()}
      p-t={28}
      contentBackground={`linear-gradient(214deg, ${theme.colors.blue12},${theme.colors.green12} 79%)`}
      sideNavVisible={false}
      maxContentWidth={1136}
      languageButtonInlineWithSlogan
    >
      {children}
    </SideNavLayout>
  );
};
export default ErrorLayout;
