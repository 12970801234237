import { FormLayout as FormLayoutCore, Spacing } from 'ia-react-core';
import { useSitecoreContentQuery } from 'omni-package-react-cms';
import {
  AuthenticationContext,
  ConsultationModeHeader,
  FormContext, FormLayoutProps as FormLayoutPropsCore,
  NeedHelp,
  redirectTo,
  useFooterLinks,
} from 'omni-package-react-core';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Constants from '~/app/Constants';
import ApplicationWarnings from '~/components/ApplicationWarnings';
import CanQuitGuard from '~/components/CanQuitGuard';
import { StyledBackground } from './FormLayout.styles';
import backgroundUrl from './assets/images/background.png';

export interface FormLayoutProps extends FormLayoutPropsCore { }

const FormLayout: FC<FormLayoutProps> = ({ children, returnPage, canQuit = true, needHelp = false, ...props }) => {
  const navigate = useNavigate();
  const { authenticationInfo } = useContext(AuthenticationContext);
  const authValue = authenticationInfo.isAuthenticated ? 'authenticated' : 'non-authenticated';
  const mainFooterLinksUrl = useSitecoreContentQuery(`global/footers/footer-react-${authValue}`, true);
  const footerLinksFromOmniCore = useFooterLinks() ?? [];

  const handleClose = useCallback(() => { redirectTo(returnPage, navigate); }, [returnPage, navigate]);

  const formContext = useMemo(() => ({
    returnPage,
    logOffPage: Constants.PagesUrl.LogOff,
  }), [returnPage]);

  return (
    <>
      {!canQuit && <CanQuitGuard />}
      <FormLayoutCore
        p-t={28}
        onClose={handleClose}
        mainFooterLinksUrl={mainFooterLinksUrl}
        mainFooterLinks={footerLinksFromOmniCore}
        contentBackground="transparent"
        headerBackground="white"
        {...props}
      >

        <FormLayoutCore.BeforeHeader>
          <ConsultationModeHeader />
        </FormLayoutCore.BeforeHeader>
        <FormLayoutCore.MainHeaderRightContent noColPadding>
          {needHelp && (
            <Spacing m-r-lg={24}>
              <NeedHelp />
            </Spacing>
          )}
        </FormLayoutCore.MainHeaderRightContent>
        <FormLayoutCore.Content>
          <ApplicationWarnings />
          <FormContext.Provider value={formContext}>
            {children}
          </FormContext.Provider>
        </FormLayoutCore.Content>
      </FormLayoutCore>
      <StyledBackground src={backgroundUrl} alt="background" />
    </>
  );
};
export default FormLayout;
