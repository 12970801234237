import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { CloseCircleIcon, IaIcon, IconButton, useBreakpoint } from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import { UserAccountContext } from 'omni-package-react-core';
import { StyledContentDiv, StyledDiv, StyledSpan, StyledIaSpan, StyledTertiaryButton, StyledCloseDiv, StyledIaIconDiv } from './MobileBanner.styles';

export interface MobileBannerProps { }

export const localStorageKey = 'we-client-space:mobile-banner-last-display-date';
const threeMonthsInMillis = 7776000000;

const MobileBanner: FC<MobileBannerProps> = () => {
  const isMobileScreen = useBreakpoint('xs', 'sm', 'md', 'lg');
  const { t } = useTranslation('MobileBanner');
  const { isClient, isProspect } = useContext(UserAccountContext);

  const [lastDisplayDate, setLastDisplayDate] = useState(
    localStorage.getItem(localStorageKey),
  );

  const shouldShowBanner = useMemo(() => {
    if (!lastDisplayDate) {
      return true;
    }
    const displayTimestamp = new Date(lastDisplayDate).getTime();
    const currentTimestamp = new Date().getTime();

    return currentTimestamp - displayTimestamp > threeMonthsInMillis;
  }, [lastDisplayDate]);

  const [isOpen, setIsOpen] = useState(shouldShowBanner);

  const toggleBanner = () => {
    setIsOpen(false);
    const lastOpenDate = new Date().toString();
    setLastDisplayDate(lastOpenDate);
    localStorage.setItem(localStorageKey, lastOpenDate);
  };

  useEffect(() => {
    if (shouldShowBanner) {
      setIsOpen(true);
    }
  }, [shouldShowBanner]);

  return (
    isOpen && isMobileScreen && isClient && !isProspect ? (
      <StyledContentDiv data-testid="mobileBanner">
        <StyledDiv>
          <StyledCloseDiv>
            <IconButton
              iconSize={15}
              Icon={CloseCircleIcon}
              onClick={toggleBanner}
              buttonSize={15}
              aria-label={t('closeButtonLabel')}
              data-testid="closeMobileBannerButton"
            />
          </StyledCloseDiv>
          <StyledIaIconDiv>
            <IaIcon />
          </StyledIaIconDiv>
          <StyledSpan>
            {t('title')}
            <StyledIaSpan>{t('iaTitle')}</StyledIaSpan>
          </StyledSpan>
        </StyledDiv>
        <StyledTertiaryButton
          onClick={() => {
            const { userAgent } = window.navigator;
            let url =
              'https://play.google.com/store/apps/details?id=com.inalco.mobile&pcampaignid=web_share';
            if (/Macintosh|iPad|iPhone|iPod/i.test(userAgent)) {
              url = 'https://apps.apple.com/ca/app/ia-mobile/id605490420';
            }
            window.open(url, '_blank');
          }}
          data-testid="mobileBannerButton"
        >
          {t('buttonLabel')}
        </StyledTertiaryButton>
      </StyledContentDiv>
    ) : null
  );
};

export default MobileBanner;
