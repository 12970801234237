import { Analytics, Constants } from 'omni-package-react-analytics';

class Manager {
  static instance: Analytics = null;
}

export default function getAnalyticsManager() {
  if (!Manager.instance) {
    Manager.instance = new Analytics(Constants.IDs.iaClientSpace, {});
  }

  return Manager.instance;
}
