export default {
  error: 'Sorry, something went wrong',
  quit: 'Quit',
  mainHeader: {
    ClientSpace: 'My Client Space',
    profile: 'Profile',
    notifications: 'Notifications',
    logOff: 'Log off',
  },
  menu: {
    home: 'Home',
    group_insurance: 'Group insurance',
    life_insurance: 'Life insurance',
    indiv_saving: 'Individual savings and retirement',
    group_savings: 'Group savings and retirement',
    doc: 'My documents',
    profile: 'My profile',
    contact_us: 'Contact us',
    back: 'Back to old application',
  },
  date: {
    today: 'Today',
    yesterday: 'Yesterday',
    day: {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    },
    month: {
      0: 'January',
      1: 'Febrary',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December',
    },
  },
};
