import { compose } from 'redux';
import { FC } from 'react';
import {
  withStore,
  withSaga,
  injectReducer,
  withTheming,
  configTranslation,
  withRouter,
  injectTranslations,
  configGrid,
  DeepPartial,
} from 'ia-react-core';
import appTheme from '~/themes/appTheme';
import { AppState } from '~/interfaces/AppState';
import appReducer from './App.reducer';
import App from './App';
import fr from './translations/fr';
import en from './translations/en';
import configAnalyticsWithDelay from './configAnalyticsWithDelay';

const initialState = {};
const storeOptions = { initialState };

const initialAppState: DeepPartial<AppState> = {
  isProfileDrawerOpen: false,
  features: {
    notifications: {
      isOpened: false,
    },
  },
};

interface ConfiguredAppProps { }

const ConfiguredApp = compose<FC<ConfiguredAppProps>>(
  withStore(storeOptions),
  withSaga,
  withTheming(appTheme),
  configTranslation({
    detection: { lookupCookie: 'ee_language' },
    saveCookie: false,
  }),
  withRouter({ basename: process.env.REACT_APP_ROUTER_BASENAME }),
  injectTranslations('App', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  configGrid,
  injectReducer({
    key: 'App',
    reducer: appReducer,
    initialState: initialAppState,
    useScope: true,
  }),
  configAnalyticsWithDelay,
)(App);

export default ConfiguredApp;
