import { compose } from 'redux';
import { FC } from 'react';
import { injectReducer } from 'ia-react-core';
import { AppActions } from '~/app/App.actions';
import { AppState } from '~/interfaces/AppState';
import appReducer from '~/app/App.reducer';
import ProfileDrawer, { ProfileDrawerProps } from './ProfileDrawer';

const initialState = {};

export default compose<FC<ProfileDrawerProps>>(
  injectReducer<ProfileDrawerProps, AppState, AppActions>({
    key: 'App.features.notifications',
    reducer: appReducer,
    initialState,
    cleanState: true,
  }),
)(ProfileDrawer);
