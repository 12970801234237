import React, { FC } from 'react';
import { Col, Row } from 'ia-react-core';
import { useLocation } from 'react-router-dom';
import Constants from '~/app/Constants';
import { StyledApplicationWarnings } from './ApplicationWarnings.styles';

export interface ApplicationWarningsProps {
}

const ApplicationWarnings: FC<ApplicationWarningsProps> = () => {
  const { pathname } = useLocation();
  const isHomePage = pathname === Constants.PagesUrl.HomePage;

  return (
    <Row>
      <Col xs={12}>
        <StyledApplicationWarnings
          data-testid="applicationWarnings"
          showInstabilityMessages={isHomePage}
          showUnavailabilityMessage
          showUnavailabilityWarning
          overrideWarningDelay={isHomePage ? null : 15}
          marginBottom={isHomePage ? 16 : 36}
        />
      </Col>
    </Row>
  );
};

export default ApplicationWarnings;
