export const CLOSE_ALERT = 'CLOSE_ALERT' as const;
export const OPEN_PROFILE_TOGGLE = 'OPEN_PROFILE_TOGGLE' as const;

export const closeAlert = () => ({
  type: CLOSE_ALERT,
});
export const openProfileToggle = () => ({
  type: OPEN_PROFILE_TOGGLE,
});

export type CloseAlert = ReturnType<typeof closeAlert>;
export type OpenProfileToggle = ReturnType<typeof openProfileToggle>;

export type AppActions = CloseAlert | OpenProfileToggle;
