import { FC, useEffect } from 'react';
import axios from 'axios';

export interface AxiosSetupProps { }

const AxiosSetup: FC<AxiosSetupProps> = () => {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access, camelcase */
  useEffect(() => {
    axios.defaults.headers.common['x-ia-user-context'] = 'client';
    axios.defaults.headers.common['x-ia-channel'] = 'web';
  });
  /* eslint-enable @typescript-eslint/no-unsafe-member-access, camelcase */
  return null;
};

export default AxiosSetup;
