import { designSystemTheme } from 'ia-react-core';
import { AppTheme } from '../interfaces/Theme';

const appTheme: AppTheme = {
  ...designSystemTheme,
  P: {
    lineHeight: 1.29,
  },
};

export default appTheme;
