import 'core-js/stable';
import 'regenerator-runtime/runtime';
import FontFaceObserver from 'fontfaceobserver';
import { render as renderCore } from 'ia-react-core';
import logger from './utils/logger';
import ConfiguredApp from './app';

const render = () => {
  renderCore(ConfiguredApp);
};

const montserrat = new FontFaceObserver('Montserrat');
const openSans = new FontFaceObserver('Open Sans');

Promise.all([
  montserrat.load(),
  openSans.load(),
]).then(() => {
  render();
}, () => {
  logger.warn('Fonts could not be loaded');
});
