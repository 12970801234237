import { PersonalInformation } from 'we-package-react-personal-informations';

export default function getPersonalInformation(personalInfo: PersonalInformation): { fullName: string; email: string } {
  const infos = [
    personalInfo?.identification?.firstName,
    personalInfo?.identification?.initials,
    personalInfo?.identification?.lastName,
  ];

  const fullName = infos.join(' ').replace(/\s+/g, ' ');
  return { fullName, email: personalInfo?.emails?.home };
}
