import React, { FC, ReactNode, useContext, useState, useEffect, useMemo } from 'react';
import { AuthenticationContext, ClaimsContext, ResourcesLoader, TermsOfUseModal as TermsOfUseModalPk, redirectTo } from 'omni-package-react-core';
import { useSitecoreJssQuery } from 'omni-package-react-cms';
import { useNavigate } from 'react-router-dom';
import { AccountConsents, AccountConsentsResource, AccountConsentsResourceInfo, AcceptTermsOfUseResource } from 'we-package-react-account';
import { openModalByName, requestResourceByName, useScopedSelector } from 'ia-react-core';
import { useDispatch } from 'react-redux';
import ErrorLayout from '~/layouts/ErrorLayout';
import { getOmniLogger } from 'omni-package-react-logging';
import getTokenManager from '~/utils/getTokenManager';
import Constants from '~/app/Constants';
import { refreshToken } from '../ActivityManager/refreshToken';

export interface TermsOfUseModalProps { children: ReactNode }

const TermsOfUseModal: FC<TermsOfUseModalProps> = ({ children }) => {
  const ctx = useContext(ClaimsContext);
  const url = useSitecoreJssQuery('/terms-of-use');
  const tokenManager = useMemo(() => getTokenManager(), []);
  const { authenticationInfo } = useContext(AuthenticationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const termsOfUseConsents: AccountConsents = useScopedSelector(AccountConsentsResourceInfo.dataDst);
  const isTermsOfUseOpen: boolean = useScopedSelector('/App.termsOfUseModal.isOpened');
  const [isTermsOfUseAccepted, setIsTermsOfUseAccepted] = useState(termsOfUseConsents?.data?.termsOfUseConsent);

  const logger = getOmniLogger('we-client-space-activity-manager');

  useEffect(() => {
    if (termsOfUseConsents && !isTermsOfUseOpen) {
      dispatch(openModalByName('termsOfUseModal'));
    }
  }, [dispatch, termsOfUseConsents, isTermsOfUseOpen]);
  if (ctx?.isTermsOfUseConsents || isTermsOfUseAccepted || termsOfUseConsents?.data?.termsOfUseConsent) {
    return <>{children}</>;
  }
  return (
    <ErrorLayout name="Error" lob="corpo">
      <ResourcesLoader>
        <ResourcesLoader.Resources>
          <AccountConsentsResource onFailure={() => redirectTo(Constants.PagesUrl.TechnicalErrorPage, navigate)} autoRequest />
          <AcceptTermsOfUseResource
            termsOfUseConsents={termsOfUseConsents}
            onFailure={() => redirectTo(Constants.PagesUrl.TechnicalErrorPage, navigate)}
            onSuccess={() => {
              setIsTermsOfUseAccepted(true);
              refreshToken(authenticationInfo.isAuthenticationProcessed, logger, tokenManager);
            }}
          />
        </ResourcesLoader.Resources>
        <ResourcesLoader.Content>
          <TermsOfUseModalPk
            url={url}
            showGlobalConsents={termsOfUseConsents?.data.showTermsOfUse}
            onConfirm={() => {
              dispatch(requestResourceByName('/App.features.termsOfUse.acceptTermsOfUseResource'));
            }}
          />
        </ResourcesLoader.Content>
      </ResourcesLoader>
    </ErrorLayout>
  );
};
export default TermsOfUseModal;
