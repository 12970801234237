import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { AppErrorBoundary, Snackbar } from 'ia-react-core';
import { ApplicationAvailabilityProvider, AuthenticationProvider, LanguageChanged, PageLoadedProvider, UserAccountProvider } from 'omni-package-react-core';
import { ErrorHandler } from 'omni-package-react-logging';
import Constants from '~/app/Constants';
import AxiosSetup from '~/components/AxiosSetup';
import ActivityManager from '~/components/ActivityManager';
import OpenIdConnectorWrapper from '~/components/OpenIdConnectorWrapper';
import { StyledApplicationLayout, StyledMainContent } from './MainLayout.styles';
import ContextWrapper from '../ContextWrapper';

export interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => (
  <StyledApplicationLayout>
    <AppErrorBoundary>
      <AxiosSetup />
      <ErrorHandler />
      <LanguageChanged />
      <Helmet />
      <Snackbar name={Constants.ComposantsName.ClientSpaceSnackBar} duration={4000} />
      <StyledMainContent>
        <AuthenticationProvider>
          <OpenIdConnectorWrapper>
            <ApplicationAvailabilityProvider>
              <ActivityManager />
              <PageLoadedProvider>
                <UserAccountProvider>
                  <ContextWrapper>
                    {children}
                  </ContextWrapper>
                </UserAccountProvider>
              </PageLoadedProvider>
            </ApplicationAvailabilityProvider>
          </OpenIdConnectorWrapper>
        </AuthenticationProvider>
      </StyledMainContent>
    </AppErrorBoundary>
  </StyledApplicationLayout>
);
export default AppLayout;
