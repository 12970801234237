import { injectTranslations } from 'ia-react-core';
import { compose } from 'redux';

import fr from './translations/fr';
import en from './translations/en';

import CanQuitGuard from './CanQuitGuard';

export default compose<typeof CanQuitGuard>(
  injectTranslations('CanQuitGuard', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(CanQuitGuard);
export * from './CanQuitGuard';
