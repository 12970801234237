import { Logger } from 'ia-react-core';

export const refreshToken = (isAuthenticated: boolean, logger: Logger, tokenManager: {
  refresh: () => Promise<string>;
}) => {
  if (isAuthenticated) {
    tokenManager.refresh()
      .catch((error: unknown) => logger.error(error));
  }
};
