import { SideNav } from 'ia-react-core';
import { getOnpremHostname } from 'omni-package-react-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchDocumentsSideNavLink } from 'we-package-react-document-centre';
import { DocumentsSummaryResource, DocumentsSummaryResourceInfo, DocumentsTypesResource, DocumentsTypesResourceInfo, useDocumentsNavLinks } from 'we-package-react-electronic-documents';
import { MessagingSummaryResource, MessagingSummaryResourceInfo, useMessagesNavLinks } from 'we-package-react-secured-messaging';
import { MyProductsResource, MyProductsResourceInfo, useProductsNavLinks } from 'we-package-react-my-products';
import isTestPageEnable from '~/utils/isTestPageEnable';

const useSideNavLinks = () => {
  const { t } = useTranslation('App');
  const productsNavLinks = useProductsNavLinks();
  const messagesNavLinks = useMessagesNavLinks();
  const documentsNavLinks = useDocumentsNavLinks();
  return [
    <MyProductsResource key="MyProductsResource" autoRequestIfEmpty requestOnLanguageChange name={`${MyProductsResourceInfo.name}SideNav`} />,
    <MessagingSummaryResource key="MessagingSummaryResource" autoRequest name={`${MessagingSummaryResourceInfo.name}SideNav`} />,
    <DocumentsTypesResource key="DocumentsTypesResource" autoRequestIfEmpty name={`${DocumentsTypesResourceInfo.name}SideNav`} />,
    <DocumentsSummaryResource key="DocumentsSummaryResource" autoRequest name={`${DocumentsSummaryResourceInfo.name}SideNav`} />,
    <SideNav.Link key="Home" exact data-testid="Home" to="/">{t('menu.home')}</SideNav.Link>,
    ...productsNavLinks,
    <SearchDocumentsSideNavLink key="FormsAndGuides" path={`${getOnpremHostname()}/cw/document-centre`} />,
    ...documentsNavLinks,
    ...messagesNavLinks,
    ...isTestPageEnable() ? [<SideNav.Link key="test" to="/testpage">TEST PAGE</SideNav.Link>] : [],
  ];
};

export default useSideNavLinks;
