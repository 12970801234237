import { compose } from 'redux';
import { injectAnalyticsScope } from 'ia-react-core';

import { FC } from 'react';
import ApplicationLayout, { ApplicationLayoutProps } from './ApplicationLayout';

export default compose<FC<ApplicationLayoutProps>>(
  injectAnalyticsScope({
    key: ({ name }: ApplicationLayoutProps) => name,
    pageValues: ({ name, lob }: ApplicationLayoutProps) => ({ page: name, lob }),
  }),
)(ApplicationLayout);
