import { refreshCurrentSession as refreshCurrentSessionAW } from 'aw-package-react-authentication';
import { Logger } from 'ia-react-core';

export const refreshCurrentSession = (isAuthenticated: boolean, logger: Logger) => {
  if (isAuthenticated) {
    if (process.env.REACT_APP_API_GATEWAY_MOCK === 'true') return;
    refreshCurrentSessionAW()
      .catch((error: unknown) => logger.error(error));
  }
};
