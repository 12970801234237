import axios, { AxiosRequestHeaders } from 'axios';
import { Constants, WorkerServices } from 'omni-package-typescript-token-manager';
import { getOpenIdConfiguration } from './getOpenIdConfiguration';
import logger from './logger';

export interface Manager {
  init: () => Promise<string>;
  authenticate: () => Promise<void>;
  logOff: () => Promise<never>;
  refresh: () => Promise<string>;
  hasClaim: (claim: string, value?: string) => Promise<boolean>;
  getToken: () => Promise<string>;
}

let manager: Manager;

export default function getTokenManager() {
  if (!manager) {
    /* istanbul ignore next */
    const worker = new WorkerServices((level: string, data: unknown) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (level === Constants.LOG_LEVEL.ERROR) {
        logger.error(data);
      } else {
        logger.info(data);
      }
    });
    const config = getOpenIdConfiguration();

    manager = {
      init() {
        return worker.init(config);
      },
      authenticate() {
        const [resolve, reject, promise] = getPromise<never>();
        worker.authenticate(config)
          .then(() => {
            axios.interceptors.request.use(async (req) => {
              req.headers = await worker.addHeaders(req.url, req.headers) as AxiosRequestHeaders;
              return req;
            });
            axios.interceptors.response.use(async (res) => {
              await worker.releaseRequest();
              return res;
            }, async (e) => {
              await worker.releaseRequest();
              throw e;
            });
            resolve();
          }).catch(reject);
        return promise;
      },
      logOff() {
        return worker.logOff();
      },
      refresh() {
        return worker.refreshToken();
      },
      getToken: worker.getToken,
      hasClaim(claim: string, value?: string) {
        const [resolve, reject, promise] = getPromise<boolean>();
        worker.evaluateClaim(claim, value)
          .then(({ hasClaim }) => {
            resolve(hasClaim);
          }).catch(reject);
        return promise;
      },
    };
  }

  return manager;
}

function getPromise<T>(): [(arg?: T) => void, (err?: unknown) => void, Promise<T>] {
  let resolve: (arg?: T) => void;
  let reject: (err?: unknown) => void;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return [resolve, reject, promise];
}
