import { media } from 'ia-react-core';
import styled from 'styled-components';

export const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -45px;
  margin-left: -45px;
  ${media.sm`
    position: relative;
    margin: 140px auto 0 auto;
    top: inherit;
    left: inherit;
  `}
`;
