import React, { FC } from 'react';
import { MainLoader } from 'ia-react-core';
import { StyledDiv } from './AppLoadingIcon.styles';

export interface AppLoadingIconProps { }

const AppLoadingIcon: FC<AppLoadingIconProps> = () => (
  <StyledDiv>
    <MainLoader />
  </StyledDiv>
);

export default AppLoadingIcon;
